import React, { Component } from "react";

import config from "../config.json";
import "../css/about.css";

export class About extends Component {
  render() {
    const d = new Date();
    let experienceYear = d.getFullYear() - config.startedAt;

    return (
      <div className="about section" id="about">
        <div className="about-inner flex column">
          <div className="section-header about-header flex column">
            <h5 className="section-small">
              <img className="blob blob-1" src="blob1.png" alt="" /> About Me
            </h5>
            <h2 className="section-title">Meet The Maker</h2>
          </div>
          <div className="about-content flex row">
            <div className="about-content-inner flex column">
              <div className="maker-about">
                <h3 className="maker-title">
                  Hi, I'm{" "}
                  <span className="maker-name">{config["name-short"]}</span>
                </h3>
                <p className="maker-description">
                I'm a full stack JavaScript developer specializing in React.js, Express.js, and Node.js. In my free time, I enjoy playing chess and writing poetry. I'm committed to continuous learning and creating innovative web applications.
                </p>
                <p className="mobile-experience"><span>6</span> years of experience</p>
              </div>
              <div className="maker-information flex row">
                <div className="info-box flex column">
                  <div className="info-title">Name</div>
                  <div className="info-text">{config.name}</div>
                </div>
                <div className="info-box flex column">
                  <div className="info-title">Email</div>
                  <div className="info-text email">{config.email}</div>
                </div>
                <div className="info-box flex column">
                  <div className="info-title">Date of birth</div>
                  <div className="info-text">12 October, 2000</div>
                </div>
                <div className="info-box flex column">
                  <div className="info-title">From</div>
                  <div className="info-text">
                    {config.city}, {config.country}.
                  </div>
                </div>
              </div>
            </div>
            <div className="about-experience">
              <h1 className="year">
                <img src="experience.png" alt="Experienced Developer" />
                {experienceYear}
              </h1>
              <p className="experience">
                Years of <span className="experience-bold">Experience</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
