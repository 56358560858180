import React, { Component } from "react";

import config from "../config.json";
import "../css/projects.css";

export class Projects extends Component {
  render() {
    let projects = config.projects.reverse();

    return (
      <div className="projects section" id="projects">
        <div className="projects-inner flex column">
          <div className="section-header projects-header flex column">
            <h5 className="section-small">
              <img className="blob blob-2" src="blob2.png" alt="" /> Projects
            </h5>
            <h2 className="section-title">My Recent Endeavors</h2>
          </div>
          <div className="projects-content flex column">
            <div className="projects-container flex column">
              {projects.map((project, i) => (
                <div
                  className={`project project-${i} flex`}
                  key={`project-${i}`}
                >
                  {/* <div className="project-image">
                    <img
                      src={process.env.PUBLIC_URL + `/${project.img}`}
                      alt=""
                    />
                  </div> */}
                  <div className="project-container flex column">
                    <div className="project-info flex column">
                      <div className="project-title flex row">
                        <div className="project-image">
                          <img
                            src={process.env.PUBLIC_URL + `/${project.img}`}
                            alt=""
                          />
                        </div>
                        <h2>{project.name} </h2>
                      </div>

                      <p>{project.desc}</p>
                      {project.url ? (
                        <a className="project-link" href={`${project.url}`}>
                          Demo Version
                        </a>
                      ) : null}
                    </div>
                    <div className="project-techs-container flex column">
                      <p>Technologies</p>
                      <div className="project-techs flex row">
                        {project.techs.map((tech, i) => (
                          <p className="project-tech" key={i}>
                            {tech}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="projects-socials flex row">
              <a
                className="projects-social-box projects-github"
                target="_blank"
                href={config.urls.github}
              >
                <div className="socials-inner flex row">
                  <img src="github.png" alt="" />
                  <div className="projects-social-inner flex column">
                    <h3>My Github</h3>
                    <p>
                      Check out my latest projects and contributions on Github
                    </p>
                  </div>
                </div>
              </a>
              <a
                className="projects-social-box projects-linkedin"
                target="_blank"
                href={config.urls.linkedin}
              >
                <div className="socials-inner flex row">
                  <img src="linkedin.png" alt="" />
                  <div className="projects-social-inner flex column">
                    <h3>My Linked.in</h3>
                    <p>
                      Discover my professional journey and skills on Linked.in
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Projects;
