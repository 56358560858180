import React, { Component } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import config from "../config.json";
import "../css/skills.css";

export class Skills extends Component {
  render() {

    return (
      <div className="skills section" id="skills">
        <div className="skills-inner flex column">
          <div className="section-header skills-header flex column">
            <h5 className="section-small">
              <img className="blob blob-2" src="blob2.png" alt="" /> Skills
            </h5>
            <h2 className="section-title">Expertise Exhibition</h2>
          </div>
        </div>
        <div className="skills-content flex row">
          {config.skills.map((skill, i) => (
            <div
              key={i}
              className={`skill flex row skill-${i}`}
            >
              <CircularProgressbar
                value={skill.value}
                strokeWidth={13}
                text={`${skill.value}%`}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0.5,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "round",

                  // Text size
                  textSize: "24px",

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  pathColor: `#eed83a`,
                  textColor: "#121212",
                  trailColor: "#f2f2eb",
                  backgroundColor: "red",
                  
                })}
              />

              <p>{skill.name}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Skills;

// <div className="skill">

//         </div>
