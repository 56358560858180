import React, { Component } from "react";
import "../css/landing.css";

export class Landing extends Component {
  render() {
    let name = "Çağatay";
    if (window?.location) {
      if (window?.location?.hostname.includes("cags")) {
        document.title = "Cags - Full Stack JavaScript Developer";
        name = "Cags";
      } else {
        document.title = "Çağatay Palaz - Full Stack JavaScript Developer";
      }
    }

    return (
      <div className="landing" id="landing">
        <div className="landing-inner flex column">
          <div className="profile">
            <img className="pfp" src="pfp.png" alt="Çağatay Palaz" />
            <h2 className="landing-title">Hello! I'm {name}</h2>
            <h2 className="landing-title">
              Full Stack <span className="yellow">JavaScript</span> Developer
            </h2>
          </div>
          <a className="button aboutme-btn flex" href="#about">
            <span>About Me</span>
          </a>
        </div>
        <div className="scroll-icon">
          <img src="scroll.png" alt="About Çağatay Palaz" />
        </div>
      </div>
    );
  }
}

export default Landing;
