import React, { Component } from "react";

import "../css/footer.css";

export class Footer extends Component {
  render() {
    const d = new Date();
    const year = d.getFullYear();

    return (
      <div className="footer flex row">
        <div className="copyright">
          <p className="copyright-text">
            Copyright ©{year} Çağatay Palaz. All rights reserved.
          </p>
        </div>
        <div className="socials flex row">
          <a
            className="social-link"
            href="https://github.com/CagsTheDev"
            target="_blank"
          >
            <img src={process.env.PUBLIC_URL + "/github.png"} alt="" />
          </a>
          <a
            className="social-link"
            href="https://www.linkedin.com/in/cagataypalaz/"
            target="_blank"
          >
            <img src={process.env.PUBLIC_URL + "/linkedin.png"} alt="" />
          </a>
          <a
            className="social-link"
            href="https://twitter.com/cagataypalaz"
            target="_blank"
          >
            <img src={process.env.PUBLIC_URL + "/twitter.png"} alt="" />
          </a>
          <a
            className="social-link"
            href="https://discordapp.com/users/148403195004059648"
            target="_blank"
          >
            <img src={process.env.PUBLIC_URL + "/discord.png"} alt="" />
          </a>
          <a
            className="social-link"
            href="https://www.instagram.com/cagsthedev/"
            target="_blank"
          >
            <img src={process.env.PUBLIC_URL + "/instagram.png"} alt="" />
          </a>
        </div>
      </div>
    );
  }
}

export default Footer;
