import React, { Component, Fragment } from "react";
import { BrowserRouter, Route, Switch, Router, Link } from "react-router-dom";

import Landing from "./components/Landing";
import About from "./components/About";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Footer from "./components/Footer";

import logo from "./logo.svg";
import "./css/main.css";
import "./css/shared.css";
import "./css/size.css"

function App() {
  return (
    <div className="main">
      <Landing />
      <About />
      <Projects />
      <Skills />
      <Footer />
    </div>
  );
}

export default App;
